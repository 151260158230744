/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import { rem } from "@src/theme"
import { Grid } from "@theme-ui/components"

import Layout from "@components/Layouts"
import MemberCard from "@components/MemberCard"
import SEO from "@components/seo"
import Banner from "@components/Banner"
import Container from "@components/Container"
import Section from "@components/Section"
import ProfileCard from "@components/ProfileCard"
import Spacer from "@components/Spacers"

const colorSets = [
  {
    bg: "purple",
    heading: "pink",
    text: "white",
  },
  {
    bg: "pink",
    heading: "darkPurple",
    text: "purple",
  },

  {
    bg: "darkPurple",
    heading: "white",
    text: "white",
  },
]

export default function Template ({
  data, // this prop will be injected by the GraphQL query below.
  ...props
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { name, position, ogdescription, image } = frontmatter
  const { membersData } = props.pageContext

  return (
    <Layout>
      <SEO
        title={`${name}`}
        pathname={props.location.pathname}
        image={image}
        description={ogdescription}
      />
      <Section
        sx={{
          background:
            "linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, rgba(243, 145, 188, 0) 0.01%, rgba(248, 155, 60, 0.5) 100%),#ffd1dc;",
          color: "darkPurple",
        }}
      >
        <MemberCard {...frontmatter} body={html} />
      </Section>

      {/* <Section>
        <Container>
          <div
            sx={{
              textAlign: "center",
              maxWidth: rem(450),
              mx: "auto",
              mb: "80px",
            }}
          >
            <Banner
              title="Staff and crew"
              fontSize={[rem(28), rem(36), rem(40)]}
            />
          </div>

          <Grid columns={[1, 1, 1, 3]} gap={0}>
            {membersData
              .filter(({ node }) => node.id !== data.markdownRemark.id)
              .filter(({ node }) => node.frontmatter.type !== "director")
              .map((member, index) => (
                <div sx={{ height: "100%" }}>
                  <ProfileCard
                    id={member.node.id}
                    key={member.node.id}
                    {...member.node.frontmatter}
                    colorSet={colorSets[index % 3]}
                  />
                </div>
              ))}
          </Grid>
          <Spacer.Medium />
          <div
            sx={{
              textAlign: "center",
              maxWidth: rem(450),
              mx: "auto",
              mb: "80px",
            }}
          >
            <Banner
              title="Board of directors"
              fontSize={[rem(28), rem(36), rem(40)]}
            />
          </div>

          <Grid columns={[1, 2, 3, 4]} gap={0}>
            {membersData
              .filter(({ node }) => node.frontmatter.type === "director")
              .map((member, index) => (
                <ProfileCard
                  id={member.node.id}
                  key={member.node.id}
                  {...member.node.frontmatter}
                  colorSet={colorSets[index % 3]}
                />
              ))}
          </Grid>
        </Container>
      </Section> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        name
        image
        headshot
        position
        ogdescription
      }
    }
  }
`
