/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import ImageBannerCard from "@components/ImageBannerCard"
import Container from "@components/Container"
import { rem } from "@src/theme"
import IconLink from "@components/IconLink"
import MarkdownBody from "@modules/MarkdownBody"
const MemberCard = ({ name, position, body, image }) => (
  <Container
    sx={{
      maxWidth: rem(842),
    }}
  >
    <ImageBannerCard title={`${name}`} image={image}>
      <section
        sx={{
          mb: 5,
        }}
      >
        <MarkdownBody html={body} />

        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconLink
            link="/people-and-stories"
            type="arrowLeft"
            label="Back to people and stories"
          />
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            sx={{
              cursor: "pointer",
              fontWeight: 700,
              color: "darkPurple",
              textDecoration: "underline",
            }}
          >
            Scroll to top
          </button>
        </div>
      </section>
    </ImageBannerCard>
  </Container>
)

MemberCard.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
}

export default MemberCard
